import { Button,Form, Input } from "antd";
import {  useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import {
  addWarehouseReducer,
  updateWarehouseREducer,
} from "../../store/warehouseReducer";
import { useParams } from "react-router-dom";


const AddWarehouse = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [namee, setName] = useState();
  const [addresss, setAddress] = useState("");

  const handlName = (evn) => {
    setName(evn.target.value);
  };
  const handleAddress = (evn) => {
    setAddress(evn.target.value);
  };

  const dispatch = useDispatch();
  const { warehouse } = useSelector((state) => state.warehouse);

  const onFinish = (values) => {
    dispatch(
      addWarehouseReducer({
        name: namee,
        address: addresss,
      })
    );
    setName("");
    setAddress("");
  };

  const updateWare = () => {
    dispatch(
      updateWarehouseREducer({
        name: namee,
        address: addresss,
        id,
      })
    );
    setName("");
    setAddress("");
  };

  useEffect(() => {
    setTimeout(() => {
      if (id) {
        setName(warehouse?.payload?.name);
        setAddress(warehouse?.payload?.address);
      }
    }, 400);
  }, [id,warehouse]);

  return (
    <div>
      <p className="text-xl pt-2">{id ? "Updateee" : "Add"} Warehouse</p>
      <div className="flex justify-center pt-2">
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={id ? updateWare : onFinish}
          style={{
            width: 300,
          }}
        >
          <Form.Item
            // name="namee"
            label="Warehouse Name"
            rules={[
              {
                required: true,
                message: "Missing Warehouse Name",
              },
            ]}
          >
            <Input name="namee" value={namee} onChange={handlName} />
          </Form.Item>
          <Form.Item
            // name="addresss"
            label="Warehouse Address"
            rules={[
              {
                required: true,
                message: "Missing Warehouse Name",
              },
            ]}
          >
            <Input name="addresss" value={addresss} onChange={handleAddress} />
          </Form.Item>
          {/* <Form.Item label="Warehouse Admin" name="warehouseAdmin">
            <Select placeholder="Select Warehouse Admin">
              {user?.map((item, key) => {
                <option key={key} item={item._id}>
                  {item.name}
                </option>;
              })}
            </Select>
          </Form.Item> */}
          <Form.Item>
            <Button htmlType="submit">{id ? "Update" : "Add"} Warehouse</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default AddWarehouse;
