import moment from "moment";
export const ConvertDate = (date) => {
    return moment(date).format("LLL");
}

export const ConvertToUTC = (date) => {
    return moment(date).utc().format("YYYY-MM-DD ");
}

export function formatDateTime(isoString) {
    // Parse the ISO string into a Date object
    const date = new Date(isoString);
  
    // Format the date part
    const datePart = date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  
    // Format the time part
    const timePart = date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  
    return { datePart, timePart };
  }
  
