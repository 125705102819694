import React, { useState } from "react";
import { Form, Input, Button, Card, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { changePasswordReducer } from "../store/loginReducer";

const { Title } = Typography;

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.login);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);

  const onFinish = () => {
    dispatch(changePasswordReducer({ oldPassword, password:newPassword }));
    
  };

  const validatePasswordConfirm = (rule, value) => {
    if (value !== newPassword) {
      setPasswordConfirmError("Passwords do not match");
    } else {
      setPasswordConfirmError('');
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
      <Card style={{ width: 400 }} className="border rounded-lg shadow-2xl bg-gray-100">
        <div className="text-center text-green-500" style={{ display: "flex", justifyContent: "center" }}>
          <Title level={2}>Change your <br /> Password</Title>
        </div>
        <Form name="change_password" className="login-form" onFinish={onFinish}>
          <Form.Item
            name="oldPassword"
            rules={[{ required: true, message: "Please enter your old password!" }]}
          >
            <Input.Password
              onChange={(e) => setOldPassword(e.target.value)}
              prefix={<LockOutlined />}
              placeholder="Old Password"
            />
          </Form.Item>

          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: "Please enter your new password!" }]}
          >
            <Input.Password
              onChange={(e) => setNewPassword(e.target.value)}
              prefix={<UserOutlined />}
              placeholder="New Password"
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            rules={[
              { required: true, message: "Please confirm your new password!" },
              { validator: validatePasswordConfirm }
            ]}
            validateStatus={passwordConfirmError ? 'error' : ''}
            help={passwordConfirmError}
          >
            <Input.Password
            value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              prefix={<LockOutlined />}
              placeholder="Confirm New Password"
              visibilityToggle={{
                visible: passwordConfirmVisible,
                onVisibleChange: setPasswordConfirmVisible,
              }}
            />
          </Form.Item>

          <Form.Item>
            <Button onClick={onFinish}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
              loading={isLoading}
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ChangePassword;