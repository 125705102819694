import { useEffect, useState } from "react";
import { Row, Col, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  inventorySearchQuery,
} from "../../store/inventoryReducer";
import { getCategoryReducerForActive } from "../../store/categoryReducers";
// import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import TableA from "../../components/tables/table";
import DownloadButton from "../../components/download";

const { Option } = Select;

const InventoryInAllStock = () => {
  const dispatch = useDispatch();

  const { allInventries, isLoading } = useSelector((state) => state.inventory);
  const { categories } = useSelector((state) => state.category);
  // const { warehouses } = useSelector((state) => state.warehouse);

  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // dispatch(getActiveWarehouseReducer());
    dispatch(getCategoryReducerForActive());
    // dispatch(getInventoryInAllStockReducer());
  }, [dispatch]);

  useEffect(() => {
    const dataSource = Object.keys(allInventries).map((key) => ({
      key,
      ...allInventries[key],
    }));

    setFilteredData(dataSource);
  }, [allInventries]);

  const searchByCategory = (category) => {
    setSelectedCategory(category);
  };

  // const searchByWarehouse = (warehouse) => {
  //   setSelectedWarehouse(warehouse);
  // };

  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  // Use useEffect to trigger the search query when state changes
  useEffect(() => {
    dispatch(
      inventorySearchQuery({
        categoryId: selectedCategory,
        productQuery: searchQuery,
      })
    );
  }, [ selectedCategory, searchQuery, dispatch]);

  // Update filtered data whenever allInventories change
  useEffect(() => {
    const dataSource = Object.keys(allInventries).map((key) => ({
      key,
      ...allInventries[key],
    }));
    setFilteredData(dataSource);
  }, [allInventries]);
  const reportEndPoint = "https://marnainventory.com/api/";

  const columnsA = [
    {
      title: "Product Code",
      width: "15%",
      render: (text, record) => <span>{record?.product?.code}</span>,
    },
    {
      title: "Product Name",
      width: "15%",
      render: (text, record) => <>
      <span>{record?.product?.name}</span>
      </>,
    },
    {
      title: "Product Specification",
      width: "15%",
      render: (text, record) => <>
      {record?.product?.specifications?.map((item)=>(
        <li>
          <span style={{color:"gray"}}>{item.name}</span> : <span> {item.value}</span>
        </li>
      ))}
      </>,
    },
    {
      title: "Product Category",
      width: "15%",
      render: (text, record) => <>
      <span>{record?.product?.category?.name}</span>
      </>,
    },
    {
      title: "Quantity",
      width: "20%",
      render: (text, record) => (
        <span>
          {record.quantity?.map((item, index) => (
            <li key={index}>
              {item.name}: <b>{item.value}</b>
            </li>
          ))}
        </span>
      ),
    },
  ];

  return (
    <div className="ml-10">
      <div>
        <p className="text-xl mb-4">Inventory in All Warehouses</p>
        <Row gutter={16}>
          {/* <Col span={6}>
            <Select
              placeholder="Select Warehouse"
              onChange={searchByWarehouse}
              style={{ marginBottom: "20px", width: "100%" }}
            >
              {warehouses?.map((item) => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col> */}

          <Col span={6}>
            <Select
              placeholder="Select Category"
              onChange={searchByCategory}
              style={{ marginBottom: "20px", width: "100%" }}
            >
              {categories?.map((item) => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={6}>
            <Input
              placeholder="Search by Product Name or Description"
              value={searchQuery}
              onChange={handleSearchQuery}
              style={{ marginBottom: "20px" }}
            />
          </Col>
          <DownloadButton
            endPoint={reportEndPoint + "inventory/stock/export/excel"}
            filter={{
              categoriesIds: [selectedCategory],
            }}
            fileName="report"
          />

          <TableA
            columnnn={columnsA}
            isLoadinggg={isLoading}
            dataaa={filteredData}
          />
        </Row>
      </div>
    </div>
  );
};

export default InventoryInAllStock;
