import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form, Input, Select } from "antd";

import { useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductReducer,
  updateProductReducer,
} from "../../store/productReducer";
import { getCategoryReducerForActive } from "../../store/categoryReducers";

const { Option } = Select;

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const App = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [qauntityFields, setQauntityFields] = useState([]);
  const { id } = useParams();
  const { product } = useSelector((state) => state.product);
  useEffect(() => {
    dispatch(getCategoryReducerForActive());
  }, [dispatch,product]);

  const { categories } = useSelector((state) => state.category);
 

  const [productData, setProductData] = useState();
  const [category, setCategory] = useState("");
  const [qauntity, setQauntity] = useState("");
  const [name, setName] = useState("");
  const [description, SetDescrpition] = useState("");



  useEffect(() => {
    setTimeout(() => {
      if (id) {
        setName(product?.payload?.name);
        setCategory(product?.payload?.category?.data);
        setQauntity(product?.payload?.quantity);
        SetDescrpition(product?.payload?.description);
        // console.log(product);
        setFields(product?.payload?.specifications);
        // form.setFieldValue({
        //   category: product?.payload?.category,
        //   name: product?.payload?.name,
        //   // qauntity: product?.payload?.quantity,
        //   description: product?.payload?.description,
        //   specifications: product?.payload?.specifications,
        // });
      }
    }, 400);
  }, [id,product]);

  const handleProductSubmit = async () => {
    const arr = Object.entries(productData).map(([key, value]) => ({
      name: key,
      value,
    }));
    dispatch(
      addProductReducer({
        name,
        category,
        description: description,
        quantity: qauntity,
        specifications: arr,
      })
    );
    setName("");
    setQauntity([]);
    setCategory([]);
    SetDescrpition("");
    setFields([]);
  };

  const updateProductOnclick = async () => {
    dispatch(
      updateProductReducer({
        id,
        name,
        category,
        description: description,
        quantity: qauntity,
      })
    );
    setName("");
    setQauntity([]);
    setCategory([]);
    SetDescrpition("");
  };


  const handleDescrpition = (event) => {
    SetDescrpition(event.target.value);
  };
  const handleName = (event) => {
    setName(event.target.value);
  };

  const onCategoryChange = (value) => {
    setFields([]);
    const selectedCategory = categories.find((item) => item._id === value);
    setFields(selectedCategory.specifications);
    setQauntityFields(selectedCategory.quantity_measurement);
    setCategory(value);
    setQauntity([]);
    setName("");
    SetDescrpition("");
  };

  const handleQauntity = (value) => {
    setQauntity(value);
  };

  const onReset = () => {
    form.resetFields();
  };
  const handleProductData = (event) => {
    event.persist();
    setProductData({
      ...productData,
      [event.target.name]: event.target.value,
    });
  };

  
  return (
    <div className="ml-4">
      <h1>{id ? "Update" : "Add"} Product</h1>
      <Row gutter={25}>
        <Col span={8}>
          <Form layout="vertical" form={form} name="control-hooks">
            <Form.Item
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Missing Product Category",
                },
              ]}
            >
              <Select
                placeholder="Select Category"
                value={category}
                name="category"
                onChange={onCategoryChange}
              >
                {categories?.map((item, key) => (
                  <Option key={key} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Product Name"
              rules={[
                {
                  required: true,
                  message: "Missing Product Name",
                },
              ]}
            >
              <Input onChange={handleName} name="name" value={name} />
            </Form.Item>

            <Form.Item
              label="Unit of Measure"
              rules={[
                {
                  required: true,
                  message: "Missing Product Quantity Measurment",
                },
              ]}
            >
              <Select
                placeholder="Select quantity"
                name="qauntity"
                value={qauntity}
                onChange={handleQauntity}
                mode="multiple"
                allowClear
              >
                {qauntityFields.map((item, key) => (
                  <option key={key} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              //  name="pr_descripition"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Missing Product Quantity Measurment",
                },
              ]}
            >
              <TextArea
                onChange={handleDescrpition}
                name="description"
                value={description}
              />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button className="mr-2" htmlType="button" onClick={onReset}>
                Reset
              </Button>

              {/* <Upload onChange={handleFileChange}>
                <Button icon={<UploadOutlined />}>Upload Excel</Button>
              </Upload> */}

              <Button
                htmlType="submit"
                onClick={id ? updateProductOnclick : handleProductSubmit}
              >
                {id ? "Update" : "Add New"} Product
              </Button>
              {/* <Button onClick={handleUpload}>Import Product</Button> */}
            </Form.Item>
          </Form>
        </Col>
        <Col span={16}>
          <Row gutter={5}>
            {id
              ? fields.map((item) => (
                  <Col span={12}>
                    <Form
                      layout="vertical"
                      form={form}
                      name="specifications"
                      style={{
                        width: 300,
                      }}
                    >
                      <Form.Item label={item.name}>
                        <Input
                          name={item.name}
                          value={item.value}
                          onChange={handleProductData}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                ))
              : ""}

            {id
              ? ""
              : fields.map((item) => (
                  <Col span={12}>
                    <Form
                      layout="vertical"
                      form={form}
                      name="specifications"
                      style={{
                        width: 300,
                      }}
                    >
                      <Form.Item name={item} label={item}>
                        <Input
                          name={item}
                          value={item}
                          onChange={handleProductData}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default App;
