import axios from "../plugins/axios";

export const createIncomingInventory = async (incomingInvData) => {
  const response = await axios.post("/api/inventory/incoming", incomingInvData);
  return response;
};

export const getIncomingInventoryOnly = async () => {
  const response = await axios.get("/api/inventory/incoming");
  return response;
};

export const getInventorysearch = async (payload) => {
  // console.log(payload)
  const response = await axios.post(
    `/api/inventory/search?warehouseId=${payload.warehouseId}&categoryId=${payload.categoryId}&productQuery=${payload.productQuery}`
  );
  return response;
};

export const getProductSearch = async (payload) => {
  const response = await axios.post("/api/products/search", payload);
  return response;
};



export const getGrnInventorySearch = async (payload) => {
  const response = await axios.post("/api/inventory/search/grn", payload);
  return response;
};

export const createOutGoingInventory = async (outgoingData) => {
  const response = await axios.post("/api/inventory/outgoing", outgoingData);
  return response;
};



export const getOutGoingInventory = async () => {
  const response = await axios.post("/api/inventory");
  return response;
};

export const getInventory = async (payload) => {
  const response = await axios.post("/api/inventory",payload);
  return response;
};

export const getAllInventoryInStock = async () => {
  const response = await axios.get("/api/inventory/stock/all");
  return response;
};

export const getAllInventoryInStockSearch = async (payload) => {
  const response = await axios.post("/api/inventory/stock/search",payload);
  return response;
};
