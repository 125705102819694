
import { Menu} from "antd";
import {
   DashboardFilled,
  FileOutlined,
  PieChartOutlined,
  BranchesOutlined,
  BellFilled,
  UserAddOutlined,
  PlusCircleOutlined,
  BankOutlined,
  ShopOutlined,
  StockOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons"
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/Marna_Logo (1).png";
import { useSelector } from "react-redux";



function Sidenav({ color }) {
  //
  const { crdentialDataForOne } = useSelector(
    (state) => state.login
  );
  //
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");


  //

  //
  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  

  const adminRole = [
    {
      holder: "Inventory System",
      lists: [
        {
          key: 1,
          icon: <DashboardFilled />,
          title: "Dashboard",
          to: "/dashboard"
        },
        //
        {
          key: 3,
          icon: <UserAddOutlined />,
          to: "/userRegistration",
          title: "User Registration"
        },
        {
          key: 4,
          icon: <PlusCircleOutlined />,
          to: "/viewUser",
          title: "View Users"
        },
        {
          key: 5,
          icon: <BranchesOutlined />,
          to: "/addProduct",
          title: "Add Product"
        },
        {
          key: 6,
          icon: <FileOutlined />,
          to: "/viewProduct",
          title: "View Products"
        },

        {
          key: 7,
          icon: <BankOutlined />,
          to: "/addWarehouse",
          title: "Add Warehouse"
        },
        {
          key: 8,
          icon: <BellFilled />,
          to: "/viewWarehouse",
          title: "View Warehouse"
        },
        // {
        //   key: 5,
        //   icon: dashboard,
        //   to: "/servicess",
        //   title: "Report"
        // },
        {
          key: 9,
          icon: <PieChartOutlined />,
          to: "/addCategory",
          title: "Add Category"

        },
        {
          key: 10,
          icon: <ShopOutlined />,
          to: "/viewCategory",
          title: "View Category"
        },
        {
          key: 2,
          icon: <PlusCircleOutlined />,
          to: "/incomingInventory",
          title: "Incoming Inventory"
        },
        {
          key: 11,
          icon: <MinusCircleOutlined />,
          to: "/outGoingInventory",
          title: "Outgoing Inventory"
        },
        {
          key: 13,
          icon: <StockOutlined />,
          to: "/inventoryInAllWarehouse",
          title: "Inventory"

        },
        {
          key: 12,
          icon: dashboard,
          to: "/transactionRange",
          title: "Transaction"
        }
      ]
    },

  ]
  ///
  const warehouseAdminRole = [
    {
      holder: "Inventory System",
      lists: [
        {
          key: 1,
          icon: <DashboardFilled />,
          title: "Dashboard",
          to: "/dashboard"
        },
        //

        {
          key: 6,
          icon: <FileOutlined />,
          to: "/viewProduct",
          title: "View Products"
        },

        {
          key: 8,
          icon: <BellFilled />,
          to: "/viewWarehouse",
          title: "View Warehouse"
        },

        {
          key: 10,
          icon: <ShopOutlined />,
          to: "/viewCategory",
          title: "View Category"
        },
        {
          key: 2,
          icon: <PlusCircleOutlined />,
          to: "/incomingInventory",
          title: "Incoming Inventory"
        },
        {
          key: 11,
          icon: <MinusCircleOutlined />,
          to: "/outGoingInventory",
          title: "Outgoing Inventory"
        },

        {
          key: 12,
          icon: dashboard,
          to: "/transactionRange",
          title: "Transaction"
        }
      ]
    },

  ]
  ///
  

  ////
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>Marna Manufacturing</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">

        {crdentialDataForOne.role === "admin" &&
          adminRole.map((item) => (
            <span>
              <Menu.Item className="menu-item-header" key="5">
                {item.holder}
              </Menu.Item>
              {item.lists.map((list) => (
                <Menu.Item key={list.key}>
                  <NavLink to={list.to}>
                    <span
                      className="icon"
                      style={{
                        background: page === "profile" ? color : "",
                      }}
                    >
                      {list.icon}
                    </span>
                    <span className="label">{list.title}</span>
                  </NavLink>
                </Menu.Item>
              ))}



            </span>
          ))
        }

        {crdentialDataForOne.role === "warehouseAdmin" &&
          warehouseAdminRole.map((item) => (
            <span>
              <Menu.Item className="menu-item-header" key="5">
                {item.holder}
              </Menu.Item>
              {item.lists.map((list) => (
                <Menu.Item key={list.key}>
                  <NavLink to={list.to}>
                    <span
                      className="icon"
                      style={{
                        background: page === "profile" ? color : "",
                      }}
                    >
                      {list.icon}
                    </span>
                    <span className="label">{list.title}</span>
                  </NavLink>
                </Menu.Item>
              ))}



            </span>
          ))
        }

      </Menu>
      {/* <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        onClick={({ key }) => {
          navigate.push(key);
        }}
        mode="inline"
        items={items}
      /> */}
      <div className="aside-footer">
        ©{new Date().getFullYear()} Powered by Andinet ICT Solution.
      </div>
    </>
  );
}

export default Sidenav;
