import axios from "../plugins/axios";

export const loginUsr = async (cridentials) => {
  const responsebkc = await axios.post("/api/users/login", cridentials);
  return responsebkc;
};

export const logoutUsr = async () => {
  const response = await axios.get("api/users/logout");
  return response;
};

export const changePassword = async (payload) => {
  const response = await axios.post("/api/users/changepassword", payload);
  return response;
}
