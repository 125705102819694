import { logoutInternal } from "../../store/loginReducer";
import { Row, Col, Badge, Dropdown, Button, Menu, Avatar, Typography } from "antd";
import { LogoutOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";

const { Text } = Typography;

function Header({ name, onPress, subName }) {
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  useEffect(() => window.scrollTo(0, 0), []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(logoutInternal());
  };

  // Dropdown menu for account actions
  const accountMenu = (
    <Menu>
      <Menu.Item key="profile-info" icon={<UserOutlined />}>
        {crdentialDataForOne?.first_name}. <b style={{ textTransform: 'uppercase' }}>({crdentialDataForOne?.role})</b>
      </Menu.Item>
      {/* <Menu.Item key="last-login" icon={<SettingOutlined />}>
        Last login: <b>{ConvertDate(User?.data?.user?.lastLogin)}</b>
      </Menu.Item> */}
      <Menu.Item key="edit-profile" icon={<SettingOutlined />}>
        <NavLink to={`/userRegistration/`+ crdentialDataForOne?._id}>Edit Profile</NavLink>
      </Menu.Item>
      <Menu.Item key="change-password" icon={<SettingOutlined />}>
        <NavLink to="/changePassword">Change Password</NavLink>
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={[24, 0]} align="middle">
      <Col span={24} md={6}>
        <div className="ant-page-header-heading">
          <Text strong style={{ textTransform: "capitalize", fontSize: "1.25rem" }}>
            Marna Inventory System
          </Text>
        </div>
      </Col>
      <Col span={24} md={18} className="header-control">
        <Badge size="small">
          <Dropdown overlay={accountMenu} placement="bottomRight" arrow>
            <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
              <Avatar
                style={{
                  backgroundColor: "#1890ff",
                  cursor: "pointer",
                  marginRight: "8px",
                }}
                icon={<UserOutlined />}
              />
              <Text>Account</Text>
            </div>
          </Dropdown>
        </Badge>
        <Button type="link" className="sidebar-toggler" onClick={onPress}>
          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
          </svg>
        </Button>
      </Col>
    </Row>
  );
}

export default Header;