import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Input, Select, Modal } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  createInventoryreducer,
  createOutgoingInventoryReducer,
  getInventorySearchReducer,
  getAllInventoryReducer,
  getInventoryGrnSearchReducer,
} from "../../store/inventoryReducer";
import { MinusCircleOutlined } from "@ant-design/icons";
import { getCategoryReducerForActive } from "../../store/categoryReducers";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import { getProductReducer } from "../../store/productReducer";
import TableA from "../../components/tables/table";
const { Option } = Select;

const OutgoingInventoryList = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    //
  };

  const [quantityData, setQuantityData] = useState([]);
  const [productQuantity, setProductQuantity] = useState();
  const [productId, setProductId] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [customer_phone, setCustomerPhone] = useState("");
  const [transaction_date,setTransactionDate] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [category] = useState("");
  const [productQuery] = useState("");
  const [warehouseName, setWarhousename] = useState("");
  const { outGoingInventory } = useSelector((state) => state.inventory);
  const [inventorystatus, setInventoryStatus] = useState("");
  const [gin, setGIN] = useState("");
  const { categories } = useSelector((state) => state.category);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { crdentialDataForOne } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductReducer());
    dispatch(getCategoryReducerForActive());
    dispatch(getActiveWarehouseReducer());
    setTimeout(() => {
      dispatch(
        crdentialDataForOne.role === "admin"
          ? getAllInventoryReducer()
          : getInventorySearchReducer({
              warehouseId: crdentialDataForOne.warehouse,
            })
      );
    }, 200);

    setQuantityData([]);
    setWarhousename(crdentialDataForOne?.warehouse);
  }, [dispatch, crdentialDataForOne]);

  const handleInventoryQuery = (event) => {
    dispatch(
      getInventorySearchReducer({
        warehouseId: crdentialDataForOne?.warehouse,
        productQuery: event.target.value,
        categoryId: category,
      })
    );
    // window.location.reload();
  };
  const handleGrnInventoryQuery = (event) => {
    dispatch(
      getInventoryGrnSearchReducer({
        grn: event.target.value,
      })
    );
    // window.location.reload();
  };

  const handleCustomerName = (event) => {
    setCustomerName(event.target.value);
  };
  const handleCustomerPhone = (event) => {
    setCustomerPhone(event.target.value);
  };
  const handleGin = (event) => {
    setGIN(event.target.value);
  };
  const handleTransactionDate = (event) => {
    setTransactionDate(event.target.value);
  }
  const addIncomingInventory = () => {
    const arr = Object.entries(quantityData).map(([key, value]) => ({
      name: key,
      value,
    }));
    dispatch(
      createInventoryreducer({
        productCategory: category,
        warehouse: crdentialDataForOne?.warehouse,
        product: productId,
        quantity: arr,
        grn: gin,
      })
    );
    setOpenModal(false);
  };

  const addOutgoingInventory = () => {
    const arr = Object.entries(quantityData).map(([key, value]) => ({
      name: key,
      value,
    }));
    dispatch(
      createOutgoingInventoryReducer({
        id: productId,
        quantity: arr,
        gin:gin,
        customer_name: customer_name,
        customer_phone: customer_phone,
        transaction_date: transaction_date,
      })
    );
    setOpenModal(false);
  };

  const DisplayModalOutgoing = (record) => {
    setProductQuantity(record?.quantity);
    setProductId(record?._id);
    setOpenModal(true);
    setInventoryStatus("outgoing");
  };

  const onCategoryChange = (event) => {
    // dispatch(getAllproductByCategory(event));
    dispatch(
      getAllInventoryReducer({
        warehouseId: crdentialDataForOne?.warehouse,
        productQuery: productQuery,
        categoryId: event,
      })
    );
  };

  const handleQuantityData = (evnt) => {
    evnt.persist();
    setQuantityData({
      ...quantityData,
      [evnt.target.name]: evnt.target.value,
    });
  };

  const onWarehouseChange = (evt) => {
    setWarhousename(evt);
  };

  const columnsA = [
    {
      title: "Product Code",
      width: "10%",
      render: (text, record) => <span>{record?.product?.code}</span>,
    },
    {
      title: "Product Name",
      width: "15%",
      render: (text, record) => <span>{record?.product?.name}</span>,
    },
    {
      title: "Product Specifications",
      width: "15%",
      render: (text, record) => (
        <span>
          {record.product?.specifications?.map((item) => (
            <li>
              <span style={{ color: "gray" }}>{item.name}</span> :{" "}
              <span> {item.value}</span>
            </li>
          ))}
        </span>
      ),
    },

    {
      title: "Category",
      width: "10%",
      render: (text, record) => <span>{record?.productCategory?.name}</span>,
    },
    {
      title: "Warehouse",
      width: "10%",
      render: (text, record) => <span>{record?.warehouse?.name}</span>,
    },
    {
      title: "Quantity",
      width: "15%",
      render: (text, record) => (
        <span>
          {record.quantity.map((item) => (
            <li>
              {item.name}: <b>{item.value}</b>
            </li>
          ))}
        </span>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <span>
          {/* <Button
            onClick={() => displayModal(record)}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button> */}
          <Button
            onClick={() => DisplayModalOutgoing(record)}
            className="mr-2"
            icon={<MinusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  return (
    <div className="ml-10">
      <Modal
        width={800}
        centered
        onOk={
          inventorystatus === "incoming"
            ? addIncomingInventory
            : () => addOutgoingInventory()
        }
        onCancel={() => setOpenModal(false)}
        title={
          inventorystatus === "incoming"
            ? "Incoming Inventory"
            : "Outgoing Inventory"
        }
        visible={openModal}
      >
        <Row gutter={5}>
          {/* {productQuantity} */}
          {/* {productQuantity} */}
          {productQuantity?.map((item) => (
            <Col xs={10} lg={10}>
              <Form
                layout="vertical"
                form={form}
                name="control-hooks"
                style={{
                  width: 300,
                }}
              >
                <Form.Item
                  className="capitalize"
                  name={item.name}
                  label={item.name}
                >
                  <Input
                    name={item.name}
                    value={item.value}
                    onChange={handleQuantityData}
                  />
                </Form.Item>
              </Form>
            </Col>
          ))}
          <Col xl={10}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                width: 300,
              }}
            >
              <Form.Item className="capitalize" name="gin" label="GIN">
                <Input name="gin" value={gin} onChange={handleGin} />
              </Form.Item>
            </Form>
          </Col>
          <Col xl={10} lg={10}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              style={{
                width: 300,
              }}
            >
              <Form.Item
                className="capitalize"
                name="Customer Name"
                label="Customer Name"
              >
                <Input
                  name="Customer Name"
                  value={customer_name}
                  onChange={handleCustomerName}
                />
              </Form.Item>
              <Form.Item
                className="capitalize"
                name="Transaction Date"
                label="Transaction date"
              >
                <Input
                  name="Transaction Date"
                  type="date"
                  value={transaction_date}
                  onChange={handleTransactionDate}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col xl={10} lg={10}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              style={{
                width: 300,
              }}
            >
              <Form.Item
                className="capitalize"
                name="Customer Phone"
                label="Customer Phone"
              >
                <Input
                  name="Customer Phone"
                  value={customer_phone}
                  onChange={handleCustomerPhone}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
      <div>
        <p className="text-xl mb-4"> Outgoing Inventory</p>
        <Row>
          <Col span={12}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                maxWidth: 400,
              }}
            >
              <Form.Item
                label="Warehouse"
                rules={[
                  {
                    required: true,
                    message: "Warehouse is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Warehouse"
                  allowClear
                  disabled={crdentialDataForOne?.role !== "admin"}
                  name="warehouseName"
                  value={warehouseName}
                  onChange={onWarehouseChange}
                >
                  {warehouses?.map((item, key) => (
                    <Option key={key} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="productCategory"
                label="Product Category"
                rules={[
                  {
                    required: true,
                    message: "Product Category is Required",
                  },
                ]}
              >
                <Select
                  className="w-96"
                  name="category"
                  value={category}
                  onChange={onCategoryChange} //
                  placeholder="Select product category"
                  allowClear
                >
                  {categories?.map((item, key) => (
                    <Option key={key} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                maxWidth: 400,
              }}
            >
              <Form.Item name="productname" label="Search">
                {/* <Select
                  placeholder="Select product"
                  value={productName}
                  name="productName"
                  // allowClear
                  onChange={onProductCange}
                >
                  {fields?.map((item, key) => (
                    <option key={key} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Select> */}
                <Input
                  value={productQuery}
                  name="productQuery"
                  onChange={handleInventoryQuery} //handleInventoryQuery
                />
              </Form.Item>
              <Form.Item name="date" label="Grn">
                <Input
                  name="productGrnQuery"
                  onChange={handleGrnInventoryQuery} //handleInventoryQuery
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row gutter={16}>
          {/* <Row gutter={5}> */}
          <Form
            layout="vertical"
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
          ></Form>

          {/* <Col span={16}>
            {quantityMeasures?.map((item) => (
              <Col span={12}>
                <Form
                  layout="vertical"
                  form={form}
                  name="control-hooks"
                  style={{
                    width: 300,
                  }}
                >
                  <Form.Item
                    name={item}
                    label={item}
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: Number,
                      },
                    ]}
                  >
                    <Input
                      type="Number"
                      name={item}
                      value={item}
                      onChange={handQauntityData}
                    />
                  </Form.Item>
                </Form>
              </Col>
            ))}
          </Col> */}

          <div>
            <div>
              <TableA columnnn={columnsA} dataaa={outGoingInventory} />
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};
export default OutgoingInventoryList;
