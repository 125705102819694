import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editTransaction, getAllTransactions,getAllTransactionsSearch, getDailyTransactions, getRangeTransactions } from "../services/transactions";
import { toast } from "react-toastify";

const intialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    transactions: [],
    transaction: {},
    transactionReport: []
};

export const getAllTransactionReducer = createAsyncThunk(
    "report/all",
    async (crdntialData, thunkApii) => {
        try {
            return await getAllTransactions(crdntialData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkApii.rejectWithValue(message);
        }
    }
);

export const editTransactionReducer = createAsyncThunk(
    "report/transaction/edit",
    async (crdntialData, thunkApii) => {
        try {
            return await editTransaction(crdntialData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkApii.rejectWithValue(message);
        }
    }
);

export const getTransactionsSearch = createAsyncThunk(
    "report/all/search",
    async (crdntialData, thunkApii) => {
        try {
            return await getAllTransactionsSearch(crdntialData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkApii.rejectWithValue(message);
        }
    }
);

export const getDailyTransactionReducer = createAsyncThunk(
    "report/daily",
    async (crdntialData, thunkApii) => {
        try {

            return await getDailyTransactions(crdntialData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkApii.rejectWithValue(message);
        }
    }
);

export const getRangeTransactionReducer = createAsyncThunk(
    "report/range",
    async (crdntialData, thunkApii) => {
        try {

            return await getRangeTransactions(crdntialData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkApii.rejectWithValue(message);
        }
    }
);

const transactioSlice = createSlice({
    name: "login",
    initialState: intialState,

    extraReducers: (builder) => {
        builder
            .addCase(getAllTransactionReducer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllTransactionReducer.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.isError = false;
                state.isLoading = false;
                state.transactions = action.payload?.data;
            })
            .addCase(getAllTransactionReducer.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                toast.error(action.payload);
            }).addCase(getDailyTransactionReducer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDailyTransactionReducer.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.isError = false;
                state.isLoading = false;
                state.transactions = action.payload?.data;
            })
            .addCase(getDailyTransactionReducer.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                toast.error(action.payload);
            })
            .addCase(getRangeTransactionReducer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRangeTransactionReducer.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.isError = false;
                state.isLoading = false;
                state.transactions = action.payload?.data;
            })
            .addCase(getRangeTransactionReducer.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                toast.error(action.payload);
            }).addCase(getTransactionsSearch.pending,(state,action)=>{
                state.isLoading = true;

            }).addCase(getTransactionsSearch.fulfilled,(state,action)=>{
                state.isSuccess = true;
                state.isError = false;
                state.isLoading = false;
                state.transactions = action.payload?.data;
            }).addCase(getTransactionsSearch.rejected,(state,action)=>{
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                toast.error(action.payload);
            }).addCase(editTransactionReducer.pending,(state,action)=>{
                state.isLoading = true;
            }).addCase(editTransactionReducer.fulfilled,(state,action)=>{
                state.isSuccess = true;
                state.isError = false;
                state.isLoading = false;
                toast.success("Transaction Updated Successfully");

            }).addCase(editTransactionReducer.rejected,(state,action)=>{
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                toast.error(action.payload);
            })
    },
});

export const isSuccess = (state) => state.transaction.isSuccess;
export const isError = (state) => state.transaction.isError;
export const isLoading = (state) => state.transaction.isLoading;
export const transactions = (state) => state.transaction.transactions;

export default transactioSlice.reducer;