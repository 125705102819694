

import {  Card, Row, Col, Statistic, Table } from "antd";


const inventoryData = [
  {
    id: 1,
    name: "Cup",
    quantity: 25,
    price: 10,
  },
  {
    id: 2,
    name: "Brake",
    quantity: 18,
    price: 5,
  },
  // Add more inventory data as needed
];

const App = () => {

  const columns = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => `${price.toFixed(2)}`,
    },
  ];



  //
  return (
    <div>
      {/* <Header className="text-xl pt-2" style={{ background: "#fff" }}>
          Welcome To Marna
        </Header> */}
      <Row gutter={16}>
        <Col span={8}>
          <Card>
            <Statistic title="Spare part" value={inventoryData.length} />
          </Card>
          <Card>
            <Statistic title=" Aluminium " value={inventoryData.length} />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              className=""
              title=" Galvanized "
              value={inventoryData.reduce(
                (total, item) => total + item.quantity,
                0
              )}
            />
          </Card>
          <Card>
            <Statistic
              title=" Tile "
              className=""
              value={inventoryData.reduce(
                (total, item) => total + item.quantity,
                0
              )}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title="Daily Transaction"
              value={inventoryData
                .reduce((total, item) => total + item.quantity * item.price, 0)
                .toFixed(2)}
            />
            {/* <label>Cup</label> */}
          </Card>
          <Card>
            <Statistic
              title="Total Other Products"
              value={inventoryData
                .reduce((total, item) => total + item.quantity * item.price, 0)
                .toFixed(2)}
            />
          </Card>
        </Col>
      </Row>
      <Card style={{ marginTop: "16px" }}>
        <Table columns={columns} dataSource={inventoryData} />
      </Card>
    </div>
  );
};

export default App;
