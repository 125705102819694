import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { useMediaQuery } from "react-responsive";

const App = ({ columnnn, dataaa, isLoadinggg }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [tableHeight, setTableHeight] = useState(window.innerHeight - 300);
  const [pageSize, setPageSize] = useState(10);

  // Update the height dynamically when the window is resized
  useEffect(() => {
    const handleResize = () => setTableHeight(window.innerHeight - 200);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Define columns with dynamic width and padding
  const adjustedColumns = columnnn.map((col) => ({
    ...col,
    ellipsis: true, // Enable ellipsis for overflow
    onCell: () => ({
      style: {
        padding: isMobile ? "8px 12px" : "12px 16px", // Dynamic padding based on device
      },
    }),
  }));

  return (
    <div style={{ padding: "16px" }}> {/* Add padding to the table container */}
      <Table
        size="small"
        columns={adjustedColumns}
        dataSource={dataaa}
        scroll={{ y: tableHeight }}
        loading={isLoadinggg}
        pagination={{
          pageSize: pageSize,
          onChange: (currentPage, newPageSize) => {
            setPageSize(newPageSize || pageSize); // Update the page size if changed
          },
          pageSizeOptions: isMobile ? ["5", "10"] : ["10", "20", "30", "40"],
          showSizeChanger: !isMobile,
        }}
        style={{ backgroundColor: "#fff", borderRadius: "8px" }} // Add a styled container for better UI
      />
    </div>
  );
};

export default App;