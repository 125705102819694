
import axios from "../plugins/axios";

export const createWareHouse = async (warehouseData) => {
  const responsWare = await axios.post("/api/warehouses", warehouseData);
  return responsWare;
};
export const getActiveWarehouse = async () => {
  const repon = await axios.get("/api/warehouses");
  return repon;
};
export const getRemovedWarehouse = async () => {
  const repon = await axios.get("/api/warehouses/removed/all");
  return repon;
};

export const updateWarehouse = async (payload) => {
  const responce = await axios.patch(
    "/api/warehouses/update/" + payload.id,
    payload
  );
  return responce;
};

export const deleteWarehouse = async (param) => {
  const response = await axios.delete(
    "/api/warehouses/remove/" + param.id,
    param
  );
  return response;
};

export const undoWarehouse = async (param) => {
  const response = await axios.patch(
    "/api/warehouses/activate/" + param.id,
    param
  );
  return response;
};
