import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import { createLoginReducer } from "../store/loginReducer";

import {
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import marna_logo from "../assets/images/Marna_Logo (1).png";

import { Button, Layout, Col, Form, Input, Row } from "antd";

const { Header, Footer, Content } = Layout;

const onFinishFailed = (errorInfo) => {
};
const onFinish = (values) => {
};
const App = () => {
  const navigate = useHistory();
  const [formData, setFormData] = useState({
    email: "", // this can change to phone no
    password: "",
  });
  const handleUserandPassword = (evn) => {
    setFormData({
      ...formData,
      [evn.target.name]: evn.target.value,
    });
  };

  const {  isLoading } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const handleLogin = async () => {
    dispatch(createLoginReducer(formData));
    navigate.push("/dashbord");
  };

 
  return (
    <Layout>
      <Header style={{ background: "#FAFAFA", boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)' }}>
        <div className="header-col header-brand">
          <h5 style={{
            color: "#1E90FF"
          }}>Marna Inventory System</h5>
        </div>
      </Header>


      <Content style={{ background: '#fff', minHeight: 'calc(100vh - 64px - 50px)' }}>
        <div style={{ paddingTop: "40px" }}>
          <Row gutter={[0, 0]} justify="space-around">
            <Col xs={{ span: 24, offset: 0 }}
              lg={{ span: 10, offset: 2 }}
              md={{ span: 10 }}>
              <h1 className="" style={{
                color: "#1E90FF"
              }}>Login to account</h1>

              <Form
                name="basic"
                layout="vertical"
                className="row-col"
                wrapperCol={{
                  span: 15,
                }}

                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  className="mt-5 no-star"
                  label="Email Address"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    name="email"
                    value={formData.email}
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="name@example.com"
                    onChange={handleUserandPassword}
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    value={formData.password}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    onChange={handleUserandPassword}
                    placeholder="password"
                  />
                </Form.Item>
                <Form.Item >
                  <Button
                    style={{
                      backgroundColor: '#1E90FF',
                      color: "white"

                    }}
                    className="login-form-button"
                    block
                    htmlType="submit"
                    onClick={handleLogin}

                  >

                    {isLoading ? 'Loading' : <b>Login</b>}
                  </Button>
                </Form.Item>
              </Form>
            </Col>

            <Col
              style={{ padding: 12, marginLeft: -150 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}>
              <span style={{ pointerEvents: 'none' }} >
                <img src={marna_logo} alt="marna_logo" style={{ maxWidth: "350px", maxHeight: "300px" }} ></img>
              </span>
            </Col>
          </Row>
        </div >
      </Content>

      <Footer style={{ position: 'fixed', bottom: 0, width: '100%', height: "50px" }}>

        <p className="copyright">
          {" "}
          Powered by <a href="#pablo">Andinet ICT Solution</a> © {new Date().getFullYear()}
        </p>
      </Footer>


    </Layout>

  );
};
export default App;

