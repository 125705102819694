import axios from "../plugins/axios";

export const createCategory = async (categoryData) => {
  const responceFBac = await axios.post(
    "api/productCategories/add",
    categoryData
  );
  return responceFBac;
};
export const getActiveCategory = async () => {
  const responsFrB = await axios.get("api/productCategories");
  return responsFrB;
};
export const getRemovedCategory = async () => {
  const responsFrB = await axios.get("/api/productCategories/removed/all"); /// this router mustbchang to removed router
  return responsFrB;
};

export const updateCategory = async (payload) => {
  const response = await axios.put(
    "/api/productCategories/update/" + payload.id,
    payload
  );
  return response;
};

export const deleteCategory = async (param) => {
  if (param.id === undefined) {
  } else {
    const response = await axios.delete(
      "/api/productCategories/remove/" + param.id,
      param
    );
    return response;
  }
};

export const undoCategory = async (payload) => {
  const response = await axios.put(
    "/api/productCategories/activate/" + payload.id
  );
  return response;
};
