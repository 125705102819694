import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../components/tables/table";
import { useEffect, useState } from "react";
import { getCategoryReducerForActive } from "../../store/categoryReducers";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  editTransactionReducer,
  // getAllTransactionReducer,
  getDailyTransactionReducer,
  getRangeTransactionReducer,
  getTransactionsSearch,
} from "../../store/transactionReducers";
import { formatDateTime } from "../../utils/moment";
import {
  Row,
  Select,
  Col,
  Input,
  Button,
  Form,
  Modal,
  Checkbox,
  Tooltip,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
const { Option } = Select;
const ProductReport = () => {
  const dispatch = useDispatch();
  const { transactions, isLoading } = useSelector((state) => state.transaction);
  const { categories } = useSelector((state) => state.category);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { crdentialDataForOne } = useSelector((state) => state.login);
  useEffect(() => {
    dispatch(getActiveWarehouseReducer());
    dispatch(getCategoryReducerForActive());

    // dispatch(getAllTransactionReducer());
  }, [dispatch]);

  const [transactionType, setTransactionType] = useState("");
  const [warehose, setWarehose] = useState("");
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [searchBy, setSearchBy] = useState("product");
  const [searchQuery, setSearchQuery] = useState("");
  const [endDate, setEndDate] = useState("");
  const [daily, setDaily] = useState("daily");
  const [openModal, setOpenModal] = useState(false);
  const [updateQuantity, setUpdateQuantity] = useState([]);
  const [transactionIsVoid, setTransactionIsVoid] = useState(true);
  const [transactionId, setTransactionId] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    dispatch(
      getTransactionsSearch({
        searchBy: searchBy,
        query: searchQuery,
      })
    );
  }, [searchBy, searchQuery, dispatch]);
  const handleTransactionType = (event) => {
    setTransactionType(event);
  };
  const handleTransactionIsVoid = (event) => {
    setTransactionIsVoid(event.target.checked);
  };
  const handleUpdateQuantity = (event, id) => {
    const newValue = event.target.value;

    // Update the `updateQuantity` array with the modified value
    const updatedQuantities = updateQuantity.map((item) =>
      item.id === id ? { ...item, value: newValue } : item
    );

    setUpdateQuantity(updatedQuantities);
  };
  const handleTransactionCategory = (event) => {
    setCategory(event);
  };
  const handleTransactionWarehouse = (event) => {
    setWarehose(event);
  };
  const handleOpenModal = (record) => {
    console.log(record);
    setOpenModal(true);
    setTransactionId(record._id);
    setUpdateQuantity(record.quantity);
  };

  const handleTransactionTime = (event) => {
    setDaily(event);
    if (event === "daily") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const handleTransactionSTartDate = (event) => {
    setStartDate(event.target.value);
  };
  const editTransaction = () => {
    dispatch(
      editTransactionReducer({
        id: transactionId,
        update_quantity: updateQuantity,
        transactionIsVoid: transactionIsVoid,
      })
    );
  };
  const handleTransactionEndDate = (event) => {
    setEndDate(event.target.value);
  };
  const handleSearchBy = (event) => {
    setSearchBy(event);
  };
  const handleSearchTransaction = () => {
    if (daily === "daily" && startDate) {
      setIsDisabled(true);
      dispatch(
        getDailyTransactionReducer({
          date: startDate,
          category,
          warehouse: warehose,
          type: transactionType,
        })
      );
    } else {
      dispatch(
        getRangeTransactionReducer({
          start_date: startDate,
          end_date: endDate,
          category,
          warehouse:
            crdentialDataForOne.role === "admin"
              ? warehose
              : crdentialDataForOne.warehouse,
          type: transactionType,
        })
      );
    }
  };

  const columns = [
    {
      title: "Product",
      render: (text, record) => (
        <>
          <span style={{ fontWeight: "bolder" }}>
            {record.inventory?.product?.name},
          </span>
          <p>{record?.inventory?.product?.code}</p>
          <p style={{ fontSize: "11px" }}>
            {record.inventory?.productCategory?.name}
          </p>
        </>
      ),
    },
    {
      title: "Product Specifications",
      render: (text, record) => (
        <>
          {record?.inventory?.product?.specifications?.map((item) => (
            <li>
              <span style={{ color: "gray" }}>{item.name}</span> :{" "}
              <span> {item.value}</span>
            </li>
          ))}
        </>
      ),
    },
    {
      title: "Warehouse",
      render: (text, record) => (
        <span>{record.inventory?.warehouse?.name}</span>
      ),
    },

    {
      title: "Transaction",
      render: (text, record) => (
        <>
          <span style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
            {record.transaction_type}
          </span>
          <p>{formatDateTime(record?.createdAt).datePart}</p>
          <p>
            {record.transaction_type === "Incoming"
              ? "GRN: " + record.grn
              : "GIN: " + record.gin}
          </p>
          <span>
            By: {record?.user?.first_name + " " + record?.user?.last_name}
          </span>
          {record?.customer_name ? (
            <>
              <p>To: {record?.customer_name}</p>
              <p>
                Phone: {record?.customer_phone ? record?.customer_phone : "NA"}
              </p>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Latest",
      render: (text, record) => (
        <>
          {record.latestQuantity?.map((item) => (
            <div
              className="flex flex-row"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginRight: "5px",
              }}
            >
              <p className="mr-4">{item.name}</p>
              <span className="font-bold">{item.value}</span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Quantity",
      render: (text, record) => (
        <>
          {record.quantity?.map((item) => (
            <div
              className="flex flex-row"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginRight: "5px",
              }}
            >
              <p className="mr-2">{item.name} : </p>{" "}
              <span className="font-bold">{item.value}</span>
            </div>
          ))}
        </>
      ),
    },
  ];

  const columnsForAdmin = [
    {
      title: "Product",
      render: (text, record) => (
        <>
          <span style={{ fontWeight: "bolder" }}>
            {record.inventory?.product?.name},
          </span>
          <p>{record?.inventory?.product?.code}</p>
          <p style={{ fontSize: "11px" }}>
            {record.inventory?.productCategory?.name}
          </p>
        </>
      ),
    },
    {
      title: "Product Specifications",
      render: (text, record) => (
        <>
          {record?.inventory?.product?.specifications?.map((item) => (
            <li>
              <span style={{ color: "gray" }}>{item.name}</span> :{" "}
              <span> {item.value}</span>
            </li>
          ))}
        </>
      ),
    },
    {
      title: "Warehouse",
      render: (text, record) => (
        <span>{record.inventory?.warehouse?.name}</span>
      ),
    },

    {
      title: "Transaction",
      render: (text, record) => (
        <>
          <span style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
            {record.transaction_type}
          </span>
          <p>{formatDateTime(record?.createdAt).datePart}</p>
          <p>
            {record.transaction_type === "Incoming"
              ? "GRN: " + record.grn
              : "GIN: " + record.gin}
          </p>
          <span>
            By: {record?.user?.first_name + " " + record?.user?.last_name}
          </span>
          {record?.customer_name ? (
            <>
              <p>To: {record?.customer_name}</p>
              <p>
                Phone: {record?.customer_phone ? record?.customer_phone : "NA"}
              </p>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Latest",
      render: (text, record) => (
        <>
          {record.latestQuantity?.map((item) => (
            <div
              className="flex flex-row"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginRight: "5px",
              }}
            >
              <p className="mr-4">{item.name}</p>
              <span className="font-bold">{item.value}</span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Quantity",
      render: (text, record) => (
        <>
          {record.quantity?.map((item) => (
            <div
              className="flex flex-row"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginRight: "5px",
              }}
            >
              <p className="mr-2">{item.name} : </p>{" "}
              <span className="font-bold">{item.value}</span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <span>
          <Button
            type="primary"
            size="small"
            onClick={() => handleOpenModal(record)}
          >
            Edit
          </Button> <span>{record?.updates.length > 0 ? 'Edited' : ''}</span>
        </span>
      ),
    },
  ];

  return (
    <div>
      <div className="mb-2">
        <div
          style={{
            padding: "20px",
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
          }}
        >
          <Modal
            width={600}
            centered
            onOk={editTransaction}
            onCancel={() => setOpenModal(false)}
            title="Edit transaction"
            visible={openModal}
          >
            <div style={{ padding: "20px" }}>
              <Checkbox
                checked={transactionIsVoid}
                onChange={handleTransactionIsVoid}
              >
                Void Transaction
                <Tooltip title="Voiding a transaction will set it to null">
                  <QuestionCircleOutlined style={{ marginLeft: "5px" }} />
                </Tooltip>
              </Checkbox>
            </div>
            {transactionIsVoid ? (
              ""
            ) : (
              <Row gutter={10}>
                <Col xs={24} sm={12} lg={8}>
                  <Form>
                    {updateQuantity?.map((item) => {
                      return (
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10'}}>
                        <p style={{textTransform:'uppercase',marginRight:'10px',fontSize:'16px'}}>{item.name}</p>
                        <Form.Item  key={item.id}>
                          <Input
                            value={item.value}
                            onChange={(e) => handleUpdateQuantity(e, item.id)}
                          />
                        </Form.Item>
                        </div>
                        
                      );
                    })}
                  </Form>
                </Col>
              </Row>
            )}
          </Modal>
          {/* First Row */}
          <Row gutter={10} className="pl-3">
            <Col xs={24} sm={12} lg={8}>
              <Select
                style={{ width: "100%" }}
                onChange={handleTransactionType}
                className="w-full"
                defaultValue="All Transactions"
                options={[
                  { value: "", label: "All Transactions" },
                  { value: "Incoming", label: "Incoming Transactions" },
                  { value: "Outgoing", label: "Outgoing Transactions" },
                ]}
                placeholder="Transaction Type"
              />
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <Select
                onChange={handleTransactionCategory}
                style={{ width: "100%" }}
                className="w-full"
                defaultValue="All Categories"
                placeholder="Category"
              >
                <option value="">All Categories</option>
                {categories?.map((item) => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Select
                onChange={handleTransactionWarehouse}
                style={{ width: "100%" }}
                className="w-full"
                defaultValue="All Warehouses"
                placeholder="Warehouse"
              >
                <option value="">All Warehouses</option>
                {warehouses?.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </Col>
          </Row>

          {/* Second Row */}
          <Row
            gutter={[16, 16]}
            className="pl-3"
            style={{
              marginTop: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Col xs={24} sm={12} lg={6}>
              <Select
                style={{ width: "100%", height: "20px" }}
                value={daily}
                onChange={handleTransactionTime}
                placeholder="Date Range"
                defaultValue="daily"
              >
                <option value="daily">Daily</option>
                <option value="range">Range</option>
              </Select>
            </Col>
            <Col xs={24} sm={12} lg={7}>
              <Input
                type="date"
                value={startDate}
                onChange={handleTransactionSTartDate}
                placeholder="Start Date"
              />
            </Col>
            <Col xs={24} sm={12} lg={7}>
              <Input
                className="flex flex-row w-full"
                type="date"
                value={endDate}
                disabled={isDisabled}
                onChange={handleTransactionEndDate}
                placeholder="End Date"
              />
            </Col>
            <Col xs={24} sm={12} lg={4}>
              <Button
                type="primary"
                className="w-full"
                loading={isLoading}
                onClick={handleSearchTransaction}
              >
                Filter Transaction
              </Button>
            </Col>
          </Row>
          <hr />

          {/* Third Row */}
          <Row style={{ marginTop: "20px" }}>
            <Col xs={24} lg={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <label>Search By</label>
                <Select
                  defaultValue="Select filter method"
                  onChange={handleSearchBy}
                  value={searchBy}
                  style={{ flex: 1 }}
                  placeholder="Filter By"
                >
                  <option value="customer">Customer</option>
                  <option value="product">Product</option>
                  <option value="gin">GIN</option>
                  <option value="grn">GRN</option>
                </Select>
                <Input
                  value={searchQuery}
                  placeholder="Enter Search Query"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ flex: 2 }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <CustomTable
        isLoadinggg={isLoading}
        columnnn={
          crdentialDataForOne?.role === "admin" ? columnsForAdmin : columns
        }
        dataaa={transactions}
      />
    </div>
  );
};
export default ProductReport;
